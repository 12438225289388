import React from 'react'
import Section from '../components/Section'
import map from '../assets/images/map.png'
export default function People() {
  return (
    <Section title='People'>
    <div className='flex flex-col font-aptos text-primary lg:text-[1.125rem]'>
        <p className='my-8 lg:my-4'>EnergyMetriKs Team is composed of energy industry professionals, from the oil and gas upstream sector, with extensive experience with super-majors, national oil companies, large and smaller independents as well as oil and gas investment funds. We met through the years, as early as 2006, in Aksay, Houston, Lagos or Port-Gentil to consolidate mutual respect as we worked projects alongside, leading to our collaboration today on African Producing Fields Optimization, a theme that has turned central to our careers throughout the years. The nationalities of our Experts span 4 continents, ranging from Algerians to Canadians, from Kazakhs to Italians. Our experience in Producing Field Optimization spans over numerous assets in Algeria, Cameroon, Egypt, Gabon, Ivory Coast, Libya, Nigeria, Republic of Congo and Tunisia.</p>
        <img className='hidden lg:flex lg:my-4 w-[70%] lg:w-[50%] mx-auto' alt='schema' src={map}/>
        <p className='my-8 lg:my-4'>EnergyMetriKs has a network of representative in several Oil capitals of Africa in order to facilitate in-country visa, insurance, travel, residence and transportation logistics for local presence of our Teams during Project engagement. </p>
    </div>    
    </Section>
  )
}
