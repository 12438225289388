import React from 'react'
import Section from '../components/Section'
import steps from '../assets/images/steps.png'
export default function ValueProposal() {
  return (
    <Section title='Value proposal'>
        <div className='flex flex-col font-aptos text-primary lg:text-[1.125rem]'>
            <p className='my-8 lg:my-4'>EnergyMetriKs offers a full cycle service solution to Oil and Gas Operators aiming at enhancing their Producing Field Optimization strategy and actions. We are primarily focused on onshore and shelf Oil and Gas Fields on the African continent. Our paradigm-of-solution starts with full-field technical analysis and diagnosis, including Operator staff interviews, field visit and data transfer, to define a fast-track surface facility upgrade plan and a subsurface ‘quick-win’ rig-less well intervention plan. Actions are recommended based on highest expected impact on productivity and Operating Expenditure (OPEX) per barrel produced. We assist the Operator in the Field, with our Teams, in the execution and post-appraisal of both the surface and subsurface action plans to close the full cycle project loop.</p>
            <p className='my-8 lg:my-4'>EnergyMetriKs expertise spans across the complete spectrum of strategic core disciplines central to the Upstream Oil and Gas value chain. We are built around integrated poles of expertise in Reservoir Engineering, Petroleum & Production Engineering, Geology & Geophysics, Surface & Construction Engineering, Well Intervention Planning & Execution, Computer Science and Economic Valuation, with the ability to work seamlessly across disciplines.</p>
            <p className='my-8 lg:my-4'>EnergyMetriKs deploys mobile project Teams ready to relocate to or close to the Field Operator Headquarters to facilitate field visits and warrant closer communication and coordination with Operator’s staff for the engagement duration. Our Teams, on or close to Field location, are in the best position to share methodology so to most effectively reinforce the autonomy and capability of the Field Operator. We endeavor to be fully transparent with Operator’s staff on our paradigm-of-solution, fostering knowledge-sharing and assisting in tailored-capacity-building.</p>
            <p className='my-8 lg:my-4'>EnergyMetriKs defines, evaluates and recommends actionable plans, aimed at production and productivity improvement, consisting of ‘quick wins’ with surface facilities fast-track upgrades and rig-less well interventions. We assist the Operator in the field during the whole intervention campaign, with the execution of a selected set of recommended actions, evaluated and ranked for highest impact on OPEX per barrel.</p>
            <img className='hidden lg:flex w-[65%] mx-auto my-8 lg:my-4' alt='steps' src={steps} />
            <p className='my-8 lg:my-4'>EnergyMetriKs closes the full cycle loop with the post-intervention appraisal phase to assess realized incremental hydrocarbon production and well productivity improvement. This post-evaluation phase further constitutes the basis-of-design for a reservoir surveillance plan to monitor field performance to follow-up on Production Optimization effort and generate future field actions.</p>
        </div>    
    </Section>
  )
}
