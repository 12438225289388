import React, { createContext, useContext, useState } from 'react';

// Crée le contexte
const SectionsContext = createContext();

// Création du provider pour stocker les sections et la section courante
export function SectionsProvider({ children }) {
  // Les sections de ton site
  const sections = [
    { id: 1, section: 'valueProposal' },
    { id: 2, section: 'paradigmOfSolution' },
    { id: 3, section: 'fieldAssistance'},
    { id: 4, section: 'philosophy' },
    { id: 5, section: 'people' },
    { id: 6, section: 'contact' },
  ];

  // State pour stocker la section courante
  const [currentSection, setCurrentSection] = useState(sections[0].section);
  console.log(currentSection)
  // Fonction pour mettre à jour la section courante
  const changeCurrentSection = (newSection) => {
    setCurrentSection(newSection);
  };

  return (
    <SectionsContext.Provider value={{ currentSection, changeCurrentSection, sections }}>
      {children}
    </SectionsContext.Provider>
  );
}

// Hook personnalisé pour accéder au contexte des sections
export function useSections() {
  return useContext(SectionsContext);
}
