import React from 'react'
import Section from '../components/Section'
import schema from '../assets/images/schema.png'
export default function PoS() {
  return (
    <Section title='Paradigm of solution'>
        <div className='flex flex-col font-aptos text-primary lg:text-[1.125rem]'>
            <p className='my-8 lg:my-4'>EnergyMetriKs has the ability and expertise to analyze and diagnose oil and gas Producing Fields, from reservoir to wellhead, from flow-line to fluid processing facilities, all the way to fiscal meters, export pumps and compressors. We go back to the fundamentals of PVT, Rate Transient Analysis and Material Balance to quantify and refocus Proved Developed Producing reserves, Reservoir Depletion Energy, drained and by-passed Hydrocarbon-in-Place, Recovery Factor, to further estimate Proved Undeveloped, Probable and Possible Reserves. </p>
            <img className='hidden lg:flex lg:my-4 w-[80%]  mx-auto' alt='schema' src={schema}/>
            <p className='my-8 lg:my-4'>We analyze OPEX distribution in fixed and variable component to tie it up to recurring Producing Field ailments pertaining to integrity, flow assurance and productivity, be it at the well level, or in the sphere of the surface plant. We identify, isolate and short-list major causes of production impairment by impact on variable and fixed OPEX per barrel to then establish a path to remediation strategy assisted by fit-for-purpose integrated modeling techniques, matched to combined reservoir and surface response. We strive to deploy a multi-scale static modeling approach integrating core data at pore-scale, log and flow test data at well scale, seismic surveys at basin scale, while matching and integrating with time-dependent dynamic simulation. The scope of our modeling can range from fundamental Material Balance and Nodal Analysis techniques, to non-linear global gas lift optimization, to advanced mechanistic subsurface models coupled with surface network constraints to generate a safe production envelope in the presence of wax, sand or scale-related production hindrance. Quality PVT, logs, seismic, wellbore diagrams, field historic production data and OPEX transaction list are our main resource to determine the most effective action plan to increase production and productivity.</p>
        </div>    
    </Section>
  )
}