import React from 'react';
import Section from '../components/Section';

const tableData = [
  {
    Equipment: 'Slick Line',
    Action: 'Sand Control',
    ScopeOfWork: 'Sand Bailer to retrieve sediments, debris and sand from bottomhole',
    TimeToImpact: '3',
    PotentialImpact: 'Low',
    Depth: 'Shallow',
    Count: '1'
  },
  {
    Equipment: 'Slick Line',
    Action: 'Sand Control',
    ScopeOfWork: 'Inner Sand Screens for well sorted large diameter sand grains',
    TimeToImpact: '3',
    PotentialImpact: 'Medium',
    Depth: 'Shallow',
    Count: '2'
  },
  {
    Equipment: 'Slick Line',
    Action: 'Gas Lift Optimization',
    ScopeOfWork: 'Add a deeper GL injection point with a Kinley insert and set a pack-off',
    TimeToImpact: '3',
    PotentialImpact: 'Medium',
    Depth: 'Shallow',
    Count: '3'
  },
  {
    Equipment: 'Slick Line',
    Action: 'Permanent Scale/Wax Prevention',
    ScopeOfWork: 'Permanent scraping to reduce deferred production, decrease risk of stuck SL',
    TimeToImpact: '3',
    PotentialImpact: 'Medium',
    Depth: 'Shallow',
    Count: '4'
  },
  {
    Equipment: 'Slick Line',
    Action: 'Zone Change',
    ScopeOfWork: 'Modify perforation zones and shutoff previous perfed interval',
    TimeToImpact: '3',
    PotentialImpact: 'Medium',
    Depth: 'Shallow',
    Count: '5'
  },
  {
    Equipment: 'Slick Line',
    Action: 'Additional Perfs or Re-Perfs',
    ScopeOfWork: 'Add perforation zones',
    TimeToImpact: '4',
    PotentialImpact: 'Medium',
    Depth: 'Shallow',
    Count: '6'
  },
  {
    Equipment: 'Bull Head Pumps',
    Action: 'Inject H2S Scavenger',
    ScopeOfWork: 'Prevent corrosion of infrastructure and operational equipment',
    TimeToImpact: '3',
    PotentialImpact: 'Low',
    Depth: 'Shallow',
    Count: '7'
  },
  {
    Equipment: 'Bull Head Pumps',
    Action: 'HF/HCL Acid Matrix Squeeze and Selective Stimulation',
    ScopeOfWork: 'Force Acid Deep in Matrix in selected intervals',
    TimeToImpact: '4',
    PotentialImpact: 'High',
    Depth: 'Shallow',
    Count: '8'
  },
  {
    Equipment: 'Bull Head Pumps',
    Action: 'WSO Water Shut-Off (>95% Water Cut)',
    ScopeOfWork: 'Block surface area interface between reservoir and wellbore for low drawdown',
    TimeToImpact: '6',
    PotentialImpact: 'Very High',
    Depth: 'Shallow',
    Count: '9'
  },
  {
    Equipment: 'Bull Head Pumps',
    Action: 'GSO, Gas Shut-Off',
    ScopeOfWork: 'Block surface area interface between reservoir and wellbore for low drawdown wells',
    TimeToImpact: '6',
    PotentialImpact: 'Very High',
    Depth: 'Shallow',
    Count: '10'
  },
  {
    Equipment: 'Coil Tubing',
    Action: 'Well Lifting',
    ScopeOfWork: 'Well Clean-out and kicking with Nitrogen to remove downhole obstructing material and fluids',
    TimeToImpact: '2',
    PotentialImpact: 'Medium',
    Depth: 'Shallow',
    Count: '11'
  },
  {
    Equipment: 'Coil Tubing',
    Action: 'Inject H2S Scavenger',
    ScopeOfWork: 'Prevent corrosion of infrastructure and operational equipment',
    TimeToImpact: '3',
    PotentialImpact: 'Low',
    Depth: 'Deep',
    Count: '12'
  },
  {
    Equipment: 'Coil Tubing',
    Action: 'Sand Control',
    ScopeOfWork: 'Remove obstructing sand grains from screen mesh',
    TimeToImpact: '4',
    PotentialImpact: 'Medium',
    Depth: 'Deep',
    Count: '13'
  },
  {
    Equipment: 'Coil Tubing',
    Action: 'HF/HCL Acid Matrix Squeeze and Selective Stimulation',
    ScopeOfWork: 'Force Acid Deep in Matrix in selected intervals',
    TimeToImpact: '4',
    PotentialImpact: 'High',
    Depth: 'Deep',
    Count: '14'
  },
  {
    Equipment: 'Coil Tubing',
    Action: 'WSO, Water Shut-Off (>95% Water Cut)',
    ScopeOfWork: 'Block surface area interface between reservoir and wellbore for low drawdown wells',
    TimeToImpact: '6',
    PotentialImpact: 'Very High',
    Depth: 'Deep',
    Count: '15'
  },
  {
    Equipment: 'Coil Tubing',
    Action: 'GSO, Gas Shut-Off',
    ScopeOfWork: 'Block surface area interface between reservoir and wellbore for low drawdown wells',
    TimeToImpact: '6',
    PotentialImpact: 'Very High',
    Depth: 'Deep',
    Count: '16'
  },
  {
    Equipment: 'Crane',
    Action: 'Sand Control',
    ScopeOfWork: 'Screens with Gravel Pack, Screens with Frac Pac for poorly sorted sand grains, non-eruptive wells',
    TimeToImpact: '6',
    PotentialImpact: 'Medium',
    Depth: 'Shallow',
    Count: '17'
  },
  {
    Equipment: 'Crane',
    Action: 'Activation Change Out',
    ScopeOfWork: 'Switch from Natural Flow to GL, ESP, PCP, JP, etc',
    TimeToImpact: '6',
    PotentialImpact: 'Very High',
    Depth: 'Shallow',
    Count: '18'
  },
  {
    Equipment: 'Crane',
    Action: 'WSO Water Shut-Off (>95% Water Cut)',
    ScopeOfWork: 'Add Autonomous Inflow Control Devices (AICD) for high drawdown wells',
    TimeToImpact: '6',
    PotentialImpact: 'Very High',
    Depth: 'Shallow',
    Count: '19'
  },
  {
    Equipment: 'Crane',
    Action: 'GSO, Gas Shut-Off',
    ScopeOfWork: 'Add Autonomous Inflow Control Devices (AICD) for high drawdown wells',
    TimeToImpact: '6',
    PotentialImpact: 'Very High',
    Depth: 'Shallow',
    Count: '20'
  }
];

export default function FieldAssistance() {
    return (
      <Section title="Field Assistance">
        <div className='flex flex-col font-aptos text-primary lg:text-[1.125rem]'>
            <p className='my-8 lg:my-4'>EnergyMetriKs diagnosis and analysis results in a defined rigless well intervention action plan to which execution we offer to assist. We strive to make a positive difference on production in the Field. The type of interventions we assist with are rigless and do necessitate well killing procedures. Equipment required ranges from slick lines, bull heading surface pumps, coil tubing units, when available in-country, to cranes, for the case of non-eruptive shallow wells.  </p>
        </div>   
        <h2 className="font-bold text-base md:text-lg mt-4 mb-4">Detailed Well Intervention List</h2>
        <div className="overflow-auto ">
          <table className="min-w-full bg-blue-100 border border-gray-200 text-xs md:text-sm text-center">
            <thead>
              <tr className="bg-secondary">
                <th className="px-[2px] py-1 md:px-2 md:py-2 border-b">Equipment</th>
                <th className="px-[2px] py-1 md:px-2 md:py-2 border-b">Action</th>
                <th className="px-[2px] py-1 md:px-2 md:py-2 border-b">Scope of Work</th>
                <th className="px-[2px] py-1 md:px-2 md:py-2 border-b hidden md:table-cell">Time to Impact (months)</th>
                <th className="px-[2px] py-1 md:px-2 md:py-2 border-b">Production Impact</th>
                <th className="px-[2px] py-1 md:px-2 md:py-2 border-b">Well Depth</th>
                <th className="px-[2px] py-1 md:px-2 md:py-2 border-b hidden md:table-cell">Count</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={index}
                  className={`border-b ${
                    row.Equipment === 'Bull Head Pumps' || row.Equipment === 'Crane'
                      ? 'bg-primary'
                      : ''
                  }`}
                >
                  <td className="px-[2px] py-1 md:px-2 md:py-2">{row.Equipment}</td>
                  <td className="px-[2px] py-1 md:px-2 md:py-2">{row.Action}</td>
                  <td className="px-[2px] py-1 md:px-2 md:py-2 text-left">{row.ScopeOfWork}</td>
                  <td className="px-[2px] py-1 md:px-2 md:py-2 hidden md:table-cell">{row.TimeToImpact}</td>
                  <td className="px-[2px] py-1 md:px-2 md:py-2">{row.PotentialImpact}</td>
                  <td className="px-[2px] py-1 md:px-2 md:py-2">{row.Depth}</td>
                  <td className="px-[2px] py-1 md:px-2 md:py-2 hidden md:table-cell">{row.Count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="text-xs md:text-sm text-gray-500 mt-2">
          * Slick Line can be substituted by Braided Line or Wire Line <br />
          ** Time to Impact is time from project start <br />
          *** Shallow well is lesser than 2,000 m TVDSS for vertical well trajectory
        </p>
        <div className='flex flex-col font-aptos text-primary lg:text-[1.125rem]'>
            <p className='my-8 lg:my-4'>EnergyMetriKs further assists in the Field with the post-appraisal phase following the well intervention campaign including the reinforcement of the Field well surveillance strategy and plan. At completion of the project, we deliver final reports, analytical models and an index of completed tasks during the project. </p>
        </div>   
      </Section>
    );
  }