import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Start from './Start';
import ValueProposal from '../sections/ValueProposal';
import PoS from '../sections/PoS';
import Philosophy from '../sections/Philosophy.js'
import Contact from '../sections/Contact.js'
import People from "../sections/People.js"

import { useSections } from '../SectionsContext';  // Importer le contexte
import Controls from '../components/Controls';
import FieldAssistance from '../sections/FieldAssistance';

export default function Home() {
  const [showStart, setShowStart] = useState(true);

  const { currentSection } = useSections();  // Récupérer la section active du contexte

  // Utiliser useEffect pour faire une transition après 1500ms (1.5 secondes)
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowStart(false);
    }, 1500); 

    return () => clearTimeout(timer);
  }, []);

  // Fonction pour afficher la section correcte en fonction de la valeur de currentSection
  const renderSection = () => {
    switch (currentSection) {
      case 'valueProposal':
        return <ValueProposal />;
      case 'paradigmOfSolution':
        return <PoS />;
      case 'fieldAssistance':
        return <FieldAssistance />
      case 'philosophy':
        return <Philosophy />;
      case 'people':
        return <People />;
      case 'contact':
        return <Contact />;
      default:
        return <ValueProposal />; // Fallback section par défaut
    }
  };

  return (
    <div className='w-full h-full'>
      {showStart ? (
        <Start />
      ) : (
        <div className="fade-in h-full w-full">
          <Header />
          <div className='h-full w-full flex flex-row lg:relative '>
              {renderSection()} {/* Afficher dynamiquement la section */}
          </div>
        </div>
      )}
    </div>
  );
}
