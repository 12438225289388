import React from 'react';
import longLogo from '../assets/images/emkLong.png';

export default function Start() {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center animate-pulse-div'>
      <img alt='logo' src={longLogo} className='w-[250px] h-auto' />
      <p className='font-microgamma font-bold text-primary  w-[80%] text-center'>
        Producing field optimization services
      </p>
    </div>
  );
}
