import React, { useState } from 'react';
import burger from '../assets/images/burger.png';
import { useSections } from '../SectionsContext';

export default function Menu({ isMobile }) {
  const [active, setActive] = useState(false);
  
  // Utilisation du contexte pour changer la section active
  const { changeCurrentSection } = useSections(); 

  const handleSectionClick = (section) => {
    // Mettre à jour la section active dans le contexte
    changeCurrentSection(section);
    // Fermer le menu mobile après avoir cliqué sur une section
    setActive(false);
  };

  if (isMobile) {
    return (
      <>
        <div className='ml-auto'>
          <img
            alt='burger menu'
            src={burger}
            className='cursor-pointer w-[3rem] h-auto'
            onClick={() => { setActive(!active); }}
          />
        </div>
        <div className={`absolute bg-[rgb(54,96,146)] ${!active && 'hidden'} w-full top-20 left-0 z-1000`}>
          <ul className='flex flex-col items-center pt-2'>
            <li
              className='font-microgamma text-white  w-full text-center p-2 cursor-pointer'
              onClick={() => handleSectionClick('valueProposal')}
            >
              VALUE PROPOSAL
            </li>
            <li
              className='font-microgamma text-white   w-full text-center p-2 cursor-pointer'
              onClick={() => handleSectionClick('paradigmOfSolution')}
            >
              PARADIGM OF SOLUTION
            </li>
            <li
              className='font-microgamma text-white   w-full text-center p-2 cursor-pointer'
              onClick={() => handleSectionClick('fieldAssistance')}
            >
              FIELD ASSISTANCE
            </li>
            <li
              className='font-microgamma text-white  w-full text-center p-2 cursor-pointer'
              onClick={() => handleSectionClick('philosophy')}
            >
              PHILOSOPHY
            </li>
            <li
              className='font-microgamma text-white   w-full text-center p-2 cursor-pointer'
              onClick={() => handleSectionClick('people')}
            >
              PEOPLE
            </li>
            <li
              className='font-microgamma text-white w-full text-center p-2 cursor-pointer'
              onClick={() => handleSectionClick('contact')}
            >
              CONTACT
            </li>
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <div className='w-full'>
        <ul className='flex flex-row font-microgamma text-primary font-bold justify-around ml-auto -translate-y-[6px] max-w-[70rem] min-w-[50rem]'>
          <li
            className='cursor-pointer hover:text-secondary w-fit'
            onClick={() => handleSectionClick('valueProposal')}
          >
            VALUE PROPOSAL
          </li>
          <li
            className='cursor-pointer hover:text-secondary w-fit'
            onClick={() => handleSectionClick('paradigmOfSolution')}
          >
            PARADIGM OF SOLUTION
          </li>
          <li
            className='cursor-pointer hover:text-secondary w-fit'
            onClick={() => handleSectionClick('fieldAssistance')}
          >
            FIELD ASSISTANCE
          </li>
          
          <li
            className='cursor-pointer hover:text-secondary w-fit'
            onClick={() => handleSectionClick('philosophy')}
          >
            PHILOSOPHY
          </li>
          <li
            className='cursor-pointer hover:text-secondary w-fit'
            onClick={() => handleSectionClick('people')}
          >
            PEOPLE
          </li>
          <li
            className='cursor-pointer hover:text-secondary w-fit'
            onClick={() => handleSectionClick('contact')}
          >
            CONTACT
          </li>
        </ul>
      </div>
    );
  }
}
