import React, { useState } from 'react';
import Section from '../components/Section';

export default function Contact() {
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://formspree.io/f/xbljdjag', { // Remplace par ton lien Formspree
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.ok) {
        setIsSent(true);
        form.reset();
      } else {
        alert('Une erreur est survenue. Veuillez réessayer.');
      }
    } catch (error) {
      console.error("Erreur d'envoi du formulaire :", error);
      alert('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  return (
    <Section title='Contact'>
      <div className='flex flex-col font-aptos text-primary lg:text-[1.125rem]'>
        <div className='lg:w-1/2'>
          <p className='my-8 lg:my-4'>
            Please address your inquiries directly on EnergyMetriKs Producing Field Services to: <br/><br />
            EnergyMetriKs
            <br/>
            106, rue de Sèvres, 75015 Paris – France
            <br /><br />
            or<br/><br />
            <a href="mailto:farid@energymetriks.com" className="px-8 py-1 rounded bg-primary text-white font-microgamma">
              mail
            </a>
          </p>
          <p className='w-fit mt-2 mx-auto lg:mx-0'>or</p>
        </div>

        <form onSubmit={handleSubmit} className="w-full mx-auto lg:mx-0 mt-8 rounded-lg flex flex-col space-y-4 lg:px-0 px-4">
          <label>E-MAIL</label>
          <input
            type="email"
            name="email"
            required
            className="p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
          />

          <label>CONTENT</label>
          <textarea
            name="message"
            required
            rows="5"
            className="p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
          />

          <button type="submit" className="bg-primary text-white font-microgamma w-1/4 mx-auto rounded py-2">
            SEND
          </button>

          {isSent && <p className="text-green-500 mt-2">Message sent successfully!</p>}
        </form>
      </div>
    </Section>
  );
}
