import React from 'react'; // Import du fichier CSS

export default function Section({ title, children }) {
  return (
    <div className='bg-[rgba(54,96,146,0.23)] rounded-md w-[95%] h-[85%] mt-4 mx-auto lg:max-w-[80rem] lg:min-w-[60rem]'>
      <p className='font-microgamma text-primary font-bold uppercase ml-4 mt-6 lg:pl-8'>
        {title}
      </p>
      <div className='ml-4 mt-2 mb-2 mr-4 overflow-auto custom-scrollbar lg:pr-8 lg:pl-8' style={{ height: 'calc(100% - 60px)' }}>
        {children}
      </div>
    </div>
  );
}
