import React from 'react'
import Section from '../components/Section'

export default function Philosophy() {
  return (
    <Section title='Philosophy'>
        <div className='flex flex-col font-aptos text-primary lg:text-[1.125rem]'>
            <p className='my-8 lg:my-4'>EnergyMetriKs holds paramount Health, Safety and Environment adhering to the highest standard of Industry practice. In our Producing Field Optimization methodology, primary concern is devoted to the recovery of associated gas and the reduction of natural gas flaring. We are ready, when requested by the Operator, to recommend actionable, cutting-edge, modular solutions aimed at improving gas utilization. The reduction of the carbon footprint of operations is taken early into account in the conception of all Optimization strategies proposed to the Operator. We also strive to take preventive measures against any fugitive methane emission during surface upgrades and well interventions. Furthermore, our technical Teams are trained in Health and Safety measures in the presence of hydrogen sulfide, strictly adhering to ‘double-block’ operational procedures, to prevent any lethal gas leakage during interventions. In a constant effort to safeguard our human capital, our most valuable resource, all of our experts possess medical and professional certification as well as insurance coverage in line with best industry practice.</p>
            
        </div>    
    </Section>
  )
}
