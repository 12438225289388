import React from 'react';
import Home from './pages/Home.js';
import videoBg from './assets/videos/fondSite.mp4'; // Import de la vidéo
import {SectionsProvider} from './SectionsContext.js';
function App() {
  return (
    <div className="relative w-full h-screen overflow-hidden">
      {/* Ajout de la vidéo de fond */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={videoBg}
        autoPlay
        loop
        muted
      />

      {/* Contenu principal de l'application */}
      <div className="relative z-10 h-full w-full">
      <SectionsProvider>
        <Home />
      </SectionsProvider>
      </div>
    </div>
  );
}

export default App;
