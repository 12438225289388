import { useState, useEffect } from 'react';

function useIsMobile() {
  // Initialisation de l'état pour savoir si on est sur mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Fonction pour mettre à jour l'état lors du redimensionnement
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Écouter l'événement de redimensionnement
    window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur lorsque le composant est démonté
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile; // Retourne true si sur mobile, false sinon
}

export default useIsMobile;
