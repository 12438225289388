import React from 'react';
import shortLogo from '../assets/images/emkShort.png';
import useIsMobile from '../hooks/useIsMobile';
import Menu from './Menu';

export default function Header() {
  const isMobile = useIsMobile();

  return (
    <div className='w-full h-[5rem] bg-[rgba(54,96,146,0.23)] shadow-md flex flex-row items-center px-5 md:px-10 relative'>
      <img alt='logo' src={shortLogo} className='w-auto h-[4rem]' />
      <Menu isMobile={isMobile} />
    </div>
  );
}
